<template>
  <div class="flex-row justify-evenly flex-auto group">
    <div class="flex-col self-start group_2 space-y-54">
      <span class="self-start text">麦嘉金融</span>
      <div class="flex-col space-y-32">
      <span class="font_1">
        前海博燊保理（深圳）有限公司，成立于2014年，是一家集保付代理; 投资管理;
        供应链管理为一体的企业。前海博燊保理拥有严谨、完善的管理机构及高素质的管理人员，有完整、规范的风控体系，致力于在互联网时代帮助中国企业进行营销方式的革命，并使企业在这场营销变革中抢占先机。
        <br />
        <br />
      </span>
        <span class="font_1">
        前海博燊保理定位于以核心企业的应付账款和中小企业应收账款为核心标的，围绕跨境电商商家的应收应付账款开发跨境商家保理业务，同时研发线上化商业保理系统。
      </span>
      </div>
    </div>
    <img
        class="self-center image_3"
        :src="require('@/assets/aboutUs.png')"
    />
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.group {
  padding-top: 11.25rem;
  overflow-y: auto;
}
.group_2 {
  margin-top: 5.25rem;
  width: 53.13rem;
}
.space-y-54 > *:not(:first-child) {
  margin-top: 3.38rem;
}
.text {
  color: #333333;
  font-size: 2.63rem;
  font-family: Microsoft YaHei;
  line-height: 2.56rem;
}
.space-y-32 > *:not(:first-child) {
  margin-top: 2rem;
}
.font_1 {
  font-size: 1.13rem;
  font-family: Microsoft YaHei;
  line-height: 2rem;
  color: #333333;
}
.image_3 {
  width: 45rem;
  height: 27.19rem;
}
</style>